<!--
 * ======================================
 * 说明： 学生选择组件
 * 作者： Silence
 * 文件： students.vue
 * 日期： 2023/5/12 9:03
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <Dialog
    title="学生选择器"
    confirmText="确 定"
    width="1000px"
    height="600px"
    v-model="visible"
    @handleConfirm="handleConfirm"
  >
    <Search
      v-model="searchForm"
      :options="searchOptions"
      @onSearch="onFind()"
    ></Search>
    <Table
      class="mt-2"
      ref="table"
      api="/student/list"
      :isSelection="isSelection"
      :columns="studentColumns"
      :height="355"
      :isSearch="false"
      :isAction="false"
      :onParams="onParams"
      @onRowClick="onRowClick"
      @onSelectionChange="onSelectionChange"
    ></Table>
  </Dialog>
</template>
<script>
import Dialog from "@/pages/components/dialog";
import Table from "@/pages/components/table";
import Search from "../../components/search/index.vue";
export default {
  name: "",
  components: { Dialog, Table, Search },
  props: {
    isSelection: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: false,
      searchForm: {},

      studentColumns: [
        {
          field: "no",
          label: "学号",
          align: "left",
          width: "100px",
        },
        {
          field: "username",
          label: "姓名",
          align: "left",
          width: "auto,",
        },
      ],
      searchOptions: [
        {
          type: "input",
          field: "no",
          label: "学号",
          placeholder: "按学号查询",
        },
        {
          type: "input",
          field: "username",
          label: "姓名",
          placeholder: "按姓名查询",
        },
      ],

      studentData: [],
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    /**
     *  @actions:  查询参数
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/12 10:45
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    onParams() {
      return this.searchForm;
    },

    /**
     *  @actions:  打开学生选择器
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/12 9:07
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async open() {
      this.visible = true;
      await this.onFind();
    },

    /**
     *  @actions:  查询学生列表
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/12 9:09
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async onFind() {
      this.$nextTick(async () => {
        await this.$refs["table"].onFind();
      });
    },

    /**
     *  @actions:  单击事件
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/13 22:55
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    onRowClick(row) {
      this.studentData = [row];
    },

    /**
     *  @actions:  表格选中的数据
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/12 11:20
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    onSelectionChange(data) {
      this.studentData = data;
    },

    /**
     *  @actions:  确定选择数据
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/12 9:06
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleConfirm() {
      if (this.studentData && this.studentData.length) {
        this.$emit("conform", this.studentData);
        this.visible = false;
      } else {
        this.$message.error("您还没有勾选学生！");
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
