<!--
 * ======================================
 * 说明： 历年学生评分记录
 * 作者： Silence
 * 文件： student.vue
 * 日期： 2023/6/7 0:09
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div v-loading="loading">
    <Main
      class="h-full"
      ref="main"
      :columns="classColumns"
      :is-selection="false"
      :is-page="false"
      :tableData="tableData"
    >
      <template #search>
        <Search
          v-model="searchForm"
          :options="studentSearchOptions"
          @onSearch="onFind()"
        >
          <el-form-item label="学生" required>
            <el-input v-model="searchForm.name" style="width: 180px">
              <template slot="append">
                <el-button type="primary" @click="handleFind()">···</el-button>
              </template>
            </el-input>
          </el-form-item>
        </Search>
      </template>
      <template #table-expand="{ row }">
        <div v-if="row.data" class="flex p-2">
          <div class="w-20 text-center">
            <div class="p-2">评价维度</div>
            <div class="p-2">自评</div>
            <div class="p-2">互评</div>
            <div class="p-2">师评</div>
          </div>
          <template v-for="(item, index) in JSON.parse(row.data)">
            <div
              class="flex-1 text-center"
              :key="index"
              v-if="!isNaN(Number(index))"
            >
              <div class="p-2">{{ item["title"] }}</div>
              <div class="p-2">{{ item["self"] }}</div>
              <div class="p-2">{{ item["mutual"] }}</div>
              <div class="p-2">{{ item["teacher"] }}</div>
            </div>
          </template>
        </div>
      </template>
      <template #table-item-semester="{ row }">
        <template v-if="row.semester">
          {{ row.semester == 1 ? "第一学期" : "第二学期" }}
        </template>
        <template v-else>-</template>
      </template>
      <template #table-item-room_id="{ row }">
        <template v-if="row.level && row.name">
          {{ row.level
          }}{{ row.name && row.name.length > 1 ? row.name : "0" + row.name }} 班
        </template>
        <template v-else>-</template>
      </template>
      <template #table-item-data="{ row }">
        <template v-if="row.data">
          {{ Number(JSON.parse(row.data).total).toFixed(2) }}
        </template>
        <template v-else>-</template>
      </template>
    </Main>
    <Students
      :is-selection="false"
      ref="students"
      @conform="handleOnConform"
    ></Students>
  </div>
</template>
<script>
import Main from "../components/main/index.vue";
import Search from "../components/search/index.vue";
import Students from "@/pages/class/components/students.vue";
import { classColumns, studentSearchOptions } from "./config.js";
export default {
  name: "",
  components: { Main, Search, Students },
  props: {},
  data() {
    return {
      classColumns,
      studentSearchOptions,
      searchForm: {
        semesterYear: "2022",
        semester: 2,
      },
      tableData: [],
      loading: false,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    /**
     *  @actions:  选择学生
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/13 22:38
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleFind() {
      this.$refs.students.open();
    },

    /**
     *  @actions:  选中的学生
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/13 22:44
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleOnConform(data) {
      this.$set(this.searchForm, "name", data[0].username);
      this.$set(this.searchForm, "studentId", data[0].id);
    },

    /**
     *  @actions:  查询列表数据
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/7 0:14
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async onFind() {
      if (!this.searchForm.studentId && this.searchForm.studentId !== 0) {
        this.$message.error("请先选择要查询历史评分的学生");
        return false;
      }

      this.loading = true;
      this.tableData = [];
      this.$cloud
        .get("/record/year_student", { ...this.searchForm })
        .then((res) => {
          this.loading = false;
          if (typeof res === "object") {
            this.tableData = [res];
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
